import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";

const SidebarContent = () => {
  let { pathname } = useSelector(({ common }) => common);
  let { navStyle, themeType } = useSelector(({ settings }) => settings);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="home">
              <Link to="/home">
                <i className="icon icon-home" />
                <span>Anasayfa</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="tenant">
              <Link to="/tenant">
                <i className="icon icon-company" />
                <span>Firmalar</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="business-partner">
              <Link to="/business-partner">
                <i className="icon icon-crm" />
                <span>İş Ortakları</span>
              </Link>
            </Menu.Item>

            <Menu.SubMenu
              key="log"
              title={
                <span>
                  <i className="icon icon-ckeditor" />
                  <span>Loglar</span>
                </span>
              }
            >
              <Menu.Item key="user-log">
                <Link to="/user-log">
                  <i className="icon icon-profile" />
                  <span>Kullanıcı Logları</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="audit-log">
                <Link to="/audit-log">
                  <i className="icon icon-edit" />
                  <span>İşlem Logları</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="bank-log">
                <Link to="/bank-log">
                  <i className="icon icon-auth-screen" />
                  <span>Banka Logları</span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.Item key="demo-request">
              <Link to="/demo-request">
                <i className="icon icon-map-event-listener" />
                <span>Demo Talepleri</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="support-request">
              <Link to="/support-request">
                <i className="icon icon-map-event-listener" />
                <span>Destek Talepleri</span>
              </Link>
            </Menu.Item> 
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  SET_USER_DATA,
} from "constants/ActionTypes";
import RequestHandler from "../../util/RequestHandler";
import { notification } from "antd";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const setUserToken = (token) => {
  return {
    type: SET_USER_DATA,
    payload: token,
  };
};

export const userSignUp = ({ email, password, name }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    RequestHandler.post("/auth/register", {
      email: email,
      password: password,
      name: name,
    })
      .then(({ data }) => {
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          RequestHandler.defaults.headers.common["authorization"] =
            "Bearer " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SET_USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const userSignIn = (requestData) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    return RequestHandler.post(`/Auth/Login`, requestData, false).then(
      (response) => {
        if (response.success) {
          localStorage.setItem("token", JSON.stringify(response.data.token));
          localStorage.setItem(
            "expiration",
            JSON.stringify(new Date(response.data.expiration).getTime())
          );
          localStorage.setItem("userId", JSON.stringify(response.data.userId));
          localStorage.setItem("userName", JSON.stringify(response.data.userName));

          dispatch(setUserToken(response.data.token));

          notification.success({
            message: "Giriş başarılı.",
            duration: 2,
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: response.message });
        }
        return response;
      }
    );
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");

    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
  };
};

import React from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { UserOutlined } from "@ant-design/icons";

const UserInfo = () => {
  const dispatch = useDispatch();

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(userSignOut())}>Çıkış Yap</li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Avatar
        style={{ backgroundColor: "#002447" }}
        icon={<UserOutlined />}
        className="gx-size-40 gx-pointer gx-mr-3"
        alt=""
      />
    </Popover>
  );
};

export default UserInfo;

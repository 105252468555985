import React, { useState, useEffect } from "react";
import { Button, Form, Input } from "antd";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import LoginPic from "../assets/images/finekralogo.png";
import { useDispatch, useSelector } from "react-redux";
import { userSignIn } from "appRedux/actions/Auth";
const FormItem = Form.Item;

const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => {
    return auth.authUser;
  });

  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    const response = await dispatch(userSignIn(values));

    if (!response.data?.success) {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => setLoading(false);

  useEffect(() => {
    if (token !== null) {
      props.history.push("/home");
    }
  }, [token, props.history]);

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-login-header gx-text-center">
          <h1 className="gx-login-title">
            <img
              src={LoginPic}
              alt="Neature"
              style={{ marginBottom: "11%", width: "64%" }}
            />
          </h1>
        </div>
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            rules={[{ required: true, message: "Email alanı zorunludur!" }]}
            name="email"
          >
            <Input
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Email"
            />
          </FormItem>
          <FormItem
            rules={[{ required: true, message: "Şifre alanı zorunludur!" }]}
            name="password"
          >
            <Input
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Şifre"
            />
          </FormItem>
          {/* <Link className="gx-login-form-forgot" to="/forget-password">
            Şifremi Unuttum
          </Link> */}
          <FormItem className="gx-text-center">
            <Button
              onClick={() => setLoading(true)}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Giriş Yap
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default SignIn;

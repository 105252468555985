import React from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { userSignOut } from "appRedux/actions/Auth";

const UserProfile = () => {
  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      {/* <li>
        <a style={{ color: "black" }} href="/password-change">
          Şifremi Değiştir
        </a>
      </li> */}
      <li onClick={() => dispatch(userSignOut())}>Çıkış Yap</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          style={{ backgroundColor: "#002447" }}
          icon={<UserOutlined />}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="gx-avatar-name">
          {localStorage.getItem("userName")?.replace('"', "").replace('"', "")}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;

import axios from "axios";
import config from "./ApiforContext";
import { notification } from "antd";

const RequestHandler = {
  send: (endPoint, method, data, showDefaultNotification, options) => {
    const expiration = localStorage.getItem("expiration");

    if (expiration < Date.now() && !endPoint.startsWith("/Auth")) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("token");
      window.location = "/signin";
    }

    const token = localStorage.getItem("token");

    let defaultOptions = {
      method: method,
      url: endPoint.startsWith("http") ? endPoint : config.apiUrl + endPoint,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "bearer " + token?.replace('"', "").replace('"', ""),
      },
      data: method !== "get" ? data : undefined,
      params: method === "get" ? data : undefined,
    };

    if (options) {
      defaultOptions = Object.assign(defaultOptions, options);
    }

    return axios
      .request(defaultOptions)
      .then((response) => {
        if (showDefaultNotification) {
          let type = response.data.success ? "success" : "error";
          let message = response.data.message;

          notification[type]({
            message: message,
            duration: 3,
          });
        }

        return response.data;
      })
      .catch((error) => {
        let type = "error";
        let message = error.toString();

        if (error.response) {
          if (error.response.status === 406) {
            type = null;
            error.response.data.data.forEach((validationMessage) => {
              notification.warning({
                message: validationMessage,
                duration: 3,
              });
            });
          } else if (error.response.status === 400) {
            message = error.response.data;
          } else if (
            error.response.status === 403 ||
            error.response.status === 401
          ) {
            window.location = "/not-authorized-access";
            return;
          } else {
            message = error.response.data?.message
              ? error.response.data.message
              : message;
          }
        }

        if (type) {
          notification[type]({
            message: message,
            duration: 3,
          });
        }

        return error.response
          ? error.response.data
          : {
              message: message,
              success: false,
            };
      });
  },

  get: (endPoint, data, options) =>
    RequestHandler.send(endPoint, "get", data, false, options),

  post: (endPoint, data, showDefaultNotification = true, options) =>
    RequestHandler.send(
      endPoint,
      "post",
      data,
      showDefaultNotification,
      options
    ),

  put: (endPoint, data, showDefaultNotification = true, options) =>
    RequestHandler.send(
      endPoint,
      "put",
      data,
      showDefaultNotification,
      options
    ),

  delete: (endPoint, data, showDefaultNotification = true, options) =>
    RequestHandler.send(
      endPoint,
      "delete",
      data,
      showDefaultNotification,
      options
    ),
};

export default RequestHandler;

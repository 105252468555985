import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import NotFound from './NotFound/NotFound'
import UnAuthorized from './NotFound/UnAuthorized'

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home'))}/>
      <Route path={`${match.url}tenant`} component={asyncComponent(() => import('./Tenant'))}/>

      <Route path={`${match.url}user-log`} component={asyncComponent(() => import('./Log/UserLog'))}/>
      <Route path={`${match.url}audit-log`} component={asyncComponent(() => import('./Log/AuditLog'))}/>
      <Route path={`${match.url}bank-log`} component={asyncComponent(() => import('./Log/BankLog'))}/>

      <Route path={`${match.url}demo-request`} component={asyncComponent(() => import('./WebSite/DemoRequest'))}/>
      <Route path={`${match.url}business-partner`} component={asyncComponent(() => import('./BusinessPartner'))}/>

      <Route path={`${match.url}support-request`} component={asyncComponent(() => import('./SupportRequest/SupportRequest'))}/>
      <Route path={`${match.url}support-request-message/:id`} component={asyncComponent(() => import('./SupportRequest/SupportRequestMessage'))}/>

      <Route exact path = {`${match.url}not-authorized-access`} component ={UnAuthorized} />
      <Route component={NotFound} />
    </Switch>
  </div>
);

export default App;
